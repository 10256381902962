module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"steeldivewatch.net – Steeldive Watch Official Store","short_name":"💯 Steeldive Watch","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"eacfef6cb6bb8cd98f1181bef566be4b"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","url":"https://www.steeldivewatch.net/","site_name":"Steeldive Watch Official Store"},"twitter":{"handle":"@onemorewatches","site":"https://www.steeldivewatch.net","cardType":"summary_large_image"},"facebook":{"appId":"2636999079954760"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-shopify-poulo/gatsby-browser.js'),
      options: {"plugins":[],"activatePWA":true,"dirName":"E:\\learning\\gatsby\\steeldivewatches\\steeldivewatches","navigation":{"menu":[{"type":"Menu","displayName":"Watches","link":"","subMenu":[{"displayName":"Mens Watches","link":"/all-mens-watches"},{"displayName":"Mechanical Watches","link":"/all-mechanical-watches"},{"displayName":"Dive Watches","link":"/all-dive-watches"},{"displayName":"Bronze Watches","link":"/all-bronze-watches"},{"displayName":"Quartz Watches","link":"/all-quartz-watches"}]}]},"footerNavigation":{"menu":[{"type":"Link","displayName":"SHOP","link":"","subMenu":[{"displayName":"Mens","link":"/all-mens-watches"},{"displayName":"About Us","link":"/about"},{"displayName":"Order Track","link":"/track-order"}]},{"type":"Link","displayName":"HELP","link":"","subMenu":[{"displayName":"Shipping","link":"/shipping"},{"displayName":"Returns","link":"/returns"},{"displayName":"FAQ & Contact","link":"/contact"}]},{"type":"Support","link":null,"displayName":"","subMenu":[{"displayName":"Terms & Conditions","link":"/terms-of-service"},{"displayName":"Privacy Policy","link":"/privacy-policy"}]}]},"social":{"youtube":"","instagram":"","facebook":"","pinterest":"","twitter":"","snapchat":""},"paymentMethods":["visa","mastercard","amex","diners","discover","jcb","paypal"],"shopifyOptions":{"password":"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","storeUrl":"onemorewatch.myshopify.com","downloadImages":false,"shopifyConnections":["collections"],"salesChannel":"steeldive","apiVersion":"2024-04"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.steeldivewatch.net"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
